import React from 'react';

/**
 * Renders a colored dot.
 *
 * @param {Object} props The component props.
 * @param {string} props.color The color of the dot.
 */
function ColoredDot({ color }) {
    return (
        <span
            style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: '8px'
            }}
        />
    );
}

export default ColoredDot;
