import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconDeviceFloppy, IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * DynamicDialog is a component for displaying a dialog with various customizable properties.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} [props.title='Dialog Title'] - The title of the dialog.
 * @param {boolean} [props.hideActivator=false] - If true, the activator button is hidden.
 * @param {boolean} [props.overwriteOpen] - Pass a boolean to overwrite the open state of the dialog.
 * @param {string} [props.activatorText='Open Dialog'] - The text for the dialog activator.
 * @param {Object} [props.activatorProps={}] - Additional properties for the activator.
 * @param {string} [props.activatorType='button'] - The type of the activator. Can be 'button' or 'text' or 'icon'
 * @param {string} [props.toolTipText=''] - The tooltip text for the activator.
 * @param {Function} [props.onOpen] - Callback function when the dialog is opened. Must return true to open the dialog.
 * @param {Function} [props.onCancel] - Callback function when the dialog is canceled.
 * @param {Function} [props.onSubmit] - Callback function when the dialog is submitted.
 * @param {boolean} [props.disableSave=false] - If true, the save button is disabled.
 * @param {boolean} [props.fullWidth=false] - If true, the dialog takes the full width of the screen.
 * @param {string} [props.maxWidth='lg'] - The maximum width of the dialog.
 * @param {boolean} [props.alwaysFullscreen=false] - If true, the dialog is always in fullscreen mode.
 * @param {boolean} [props.neverFullscreen=false] - If true, the dialog never goes into fullscreen mode.
 * @param {boolean} [props.hideActions=false] - If true, the action buttons are hidden.
 * @param {string} [props.submitText] - The text for the submit button.
 * @param {ReactNode} [props.submitIcon] - The icon for the submit button.
 * @param {string} [props.submitButtonType] - The type of the submit button.
 * @param {Object} [props.submitButtonProps={}] - Additional properties for the submit button.
 * @param {string} [props.id] - The ID of the component.
 * @param {Object} [props.contentSx={}] - Additional styles for the content.
 * @param {ReactNode} [props.dialogActionsContent] - Additional content for the dialog actions.
 * @param {ReactNode | Function} [props.children] - The children nodes of the component.
 */
function DynamicDialog({
    title = 'Dialog Title',
    hideActivator = false,
    overwriteOpen,
    activatorText = 'Open Dialog',
    activatorProps = {},
    activatorType = 'button',
    toolTipText = '',
    onOpen,
    onCancel,
    onSubmit,
    disableSave = false,
    fullWidth = false,
    maxWidth = 'lg',
    alwaysFullscreen = false,
    neverFullscreen = false,
    hideActions = false,
    submitText,
    submitIcon,
    submitButtonType,
    submitButtonProps = {},
    id,
    contentSx = {},
    dialogActionsContent = null,
    children,
    ...rest
}) {
    const theme = useTheme();

    const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

    let fullScreen = alwaysFullscreen || smAndDown;
    if (neverFullscreen) fullScreen = false;

    const [open, setOpen] = useState(overwriteOpen ? true : false);
    const [saving, setSaving] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setOpen(overwriteOpen ? true : false);
    }, [overwriteOpen]);

    const barTextColor = fullScreen ? theme.palette.secondary.main : theme.palette.black.main;
    const barBgColor = fullScreen ? theme.palette.primary.main : theme.palette.white.main;

    async function handleOpen() {
        if (!!overwriteOpen && onOpen) {
            return await onOpen();
        }
        if (!onOpen) setOpen(true);
        else {
            const shouldOpen = await onOpen();
            if (shouldOpen) setOpen(true);
        }
    }

    async function handleCancel() {
        if (saving) return;
        if (onCancel) {
            setSaving(true);
            const shouldClose = await onCancel();
            setSaving(false);
            if (shouldClose) setOpen(false);
        } else {
            setOpen(false);
        }
    }

    async function handleSave(e) {
        e?.preventDefault();

        if (!onSubmit) setOpen(false);
        else {
            setSaving(true);
            const shouldClose = await onSubmit();
            setSaving(false);
            if (shouldClose) setOpen(false);
        }
    }

    return (
        <>
            {hideActivator ? null : (
                <Tooltip title={toolTipText} describeChild={activatorType !== 'icon'}>
                    <span id={id}>
                        {activatorType === 'icon' ? (
                            <IconButton onClick={handleOpen} {...activatorProps}>
                                {activatorText}
                            </IconButton>
                        ) : null}

                        {activatorType === 'button' ? (
                            <Button onClick={handleOpen} variant='outlined' {...activatorProps}>
                                {activatorText}
                            </Button>
                        ) : null}

                        {activatorType === 'text' ? (
                            <Box
                                component='span'
                                onClick={handleOpen}
                                {...activatorProps}
                                sx={{ cursor: 'pointer', ...activatorProps?.sx }}
                            >
                                {activatorText}
                            </Box>
                        ) : null}
                    </span>
                </Tooltip>
            )}

            <Dialog
                aria-describedby='alert-dialog-description'
                aria-labelledby='alert-dialog-title'
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={handleCancel}
                open={open}
                scroll='paper'
                {...rest}
            >
                <DialogTitle
                    sx={{
                        background: barBgColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: barTextColor,
                        minHeight: '64px',
                        py: 1.5,
                        px: 3,
                        letterSpacing: '0.2px',
                        gap: 2.5
                    }}
                >
                    <Box>{title}</Box>

                    <IconButton
                        color={fullScreen ? 'secondary' : 'black'}
                        onClick={handleCancel}
                        title={t('close')}
                    >
                        <IconX strokeWidth={3} />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        fontSize: '1em',
                        minWidth: neverFullscreen
                            ? '250px'
                            : fullScreen
                              ? 'auto'
                              : '510px !important',
                        ...contentSx
                    }}
                >
                    {typeof children === 'function'
                        ? children({ handleCancel, handleSave })
                        : children}
                </DialogContent>

                {hideActions ? (
                    <></>
                ) : (
                    <DialogActions
                        sx={{
                            background: barBgColor,
                            display: 'flex',
                            alignItems: 'center',
                            color: barTextColor,
                            minHeight: '64px',
                            py: 0,
                            px: 2.5
                        }}
                    >
                        {dialogActionsContent ? dialogActionsContent : null}
                        <Button
                            color={fullScreen ? 'white' : 'black'}
                            disabled={saving}
                            onClick={handleCancel}
                            variant='text'
                        >
                            {t('cancel')}
                        </Button>

                        <LoadingButton
                            variant='text'
                            type={submitButtonType || 'button'}
                            onClick={handleSave}
                            color={fullScreen ? 'secondary' : 'primary'}
                            loading={saving}
                            startIcon={submitIcon || <IconDeviceFloppy />}
                            disabled={disableSave}
                            {...submitButtonProps}
                        >
                            {submitText || t('save')}
                        </LoadingButton>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
}

DynamicDialog.propTypes = {
    activatorProps: PropTypes.object,
    activatorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    activatorType: PropTypes.oneOf(['button', 'icon', 'text']),
    alwaysFullscreen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    contentSx: PropTypes.object,
    disableSave: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideActions: PropTypes.bool,
    hideActivator: PropTypes.bool,
    iconButton: PropTypes.element,
    id: PropTypes.string,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    neverFullscreen: PropTypes.bool,
    onCancel: PropTypes.func,
    onOpen: PropTypes.func,
    onSubmit: PropTypes.func,
    overwriteOpen: PropTypes.bool,
    submitButtonProps: PropTypes.object,
    submitButtonType: PropTypes.string,
    submitIcon: PropTypes.element,
    submitText: PropTypes.string,
    title: PropTypes.string,
    toolTipText: PropTypes.string
};

DynamicDialog.defaultProps = {
    title: 'Dialog Title',
    hideActivator: false,
    overwriteOpen: undefined,
    activatorText: 'Open Dialog',
    activatorProps: {},
    activatorType: 'button',
    toolTipText: '',
    disableSave: false,
    fullWidth: false,
    maxWidth: 'lg',
    alwaysFullscreen: false,
    neverFullscreen: false,
    hideActions: false,
    submitButtonProps: {},
    contentSx: {}
};

export default DynamicDialog;
