import { demoModeAtom } from '@/global-store';
import OfferContactForm from '@components/OfferContactForm';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

// Banner to offer users to buy a feature
export default function FeatureOfferBanner({ product, beforeText = '', hidden = false, sx }) {
    const { t } = useTranslation();
    const [demoMode] = useAtom(demoModeAtom);

    if (hidden || demoMode) return null;

    return (
        <Grid xs={12}>
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                    border: '4px solid #000',
                    borderRadius: 1.5,
                    p: 2.5,
                    borderLeftWidth: '9px',
                    borderRightWidth: '9px',
                    ...sx
                }}
            >
                <Typography color='black.main' mb={3} variant='h2' fontWeight={600}>
                    {beforeText ? (
                        <>
                            {beforeText}
                            <br />
                            <br />
                        </>
                    ) : null}

                    {t('featureOfferBoxCallToAction', { product })}
                </Typography>

                <OfferContactForm product={product} />
            </Box>
        </Grid>
    );
}
