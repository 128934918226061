import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Este campo';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} es inválido`,
        required: ({ path }) => `${getFieldName(path)} es un campo obligatorio`,
        defined: ({ path }) => `${getFieldName(path)} debe estar definido`,
        notNull: ({ path }) => `${getFieldName(path)} no puede ser nulo`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} debe ser uno de los siguientes valores: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} no debe ser ninguno de los siguientes valores: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} debe ser del tipo \`${i18next.t(type)}\`, ` +
                `pero el valor final fue: \`${printValue(value, true)}\`` +
                (isCast ? ` (convertido de \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n Si se pretende que "nulo" sea un valor vacío, asegúrese de marcar el esquema como` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) =>
            `${getFieldName(path)} debe tener exactamente ${length} caracteres`,
        min: ({ path, min }) => `${getFieldName(path)} debe tener al menos ${min} caracteres`,
        max: ({ path, max }) => `${getFieldName(path)} no debe tener más de ${max} caracteres`,
        matches: ({ path, regex }) => `${getFieldName(path)} debe coincidir con: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} debe ser un correo electrónico válido`,
        url: ({ path }) => `${getFieldName(path)} debe ser una URL válida`,
        uuid: ({ path }) => `${getFieldName(path)} debe ser un UUID válido`,
        trim: ({ path }) =>
            `${getFieldName(path)} debe ser una cadena sin espacios al inicio o al final`,
        lowercase: ({ path }) => `${getFieldName(path)} debe estar en minúsculas`,
        uppercase: ({ path }) => `${getFieldName(path)} debe estar en mayúsculas`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} debe ser mayor o igual a ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} debe ser menor o igual a ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} debe ser menor que ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} debe ser mayor que ${more}`,
        positive: ({ path }) => `${getFieldName(path)} debe ser un número positivo`,
        negative: ({ path }) => `${getFieldName(path)} debe ser un número negativo`,
        integer: ({ path }) => `${getFieldName(path)} debe ser un entero`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} debe ser posterior a ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} debe ser anterior a ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} contiene claves no especificadas: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} debe tener al menos ${min} elementos`,
        max: ({ path, max }) => `${getFieldName(path)} no debe tener más de ${max} elementos`,
        length: ({ path, length }) => `${getFieldName(path)} debe tener ${length} elementos`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} debe ser ${i18next.t(value)}`
    }
};
