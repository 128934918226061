import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import de from 'date-fns/locale/de';

export function DueDate({ value, handleChange }) {
    const handleDateChanged = newValue => handleChange('duedate', newValue);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <DatePicker
                value={value}
                onChange={handleDateChanged}
                slotProps={{
                    textField: {
                      variant: 'standard',
                      fullWidth: true
                    },
                  }}
                renderInput={params => (
                    <TextField {...{ ...params, variant: 'standard' }} />
                )}
            />
        </LocalizationProvider>
    );
}
