import { Box, Avatar } from '@mui/material';

export default function JiraProjectInfo({ project, sx = {} }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, overflow: 'hidden', ...sx }}>
            <Avatar
                alt={`JIRA-Project-${project?.name}`}
                sx={{ width: 32, height: 32 }}
                src={project?.avatarUrls['32x32']}
            />{' '}
            {project?.name}
        </Box>
    );
}
