import { Box, IconButton, Tooltip } from '@mui/material';
import { IconEdit } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import JiraUserInfo from '../../JiraUserInfo';
import JiraUsersAutocomplete from '../../JiraUsersAutocomplete';
import { useTranslation } from 'react-i18next';

export function Assignee({ todoAssigneeUser, handleChange, jiraUsers }) {
    const { t } = useTranslation();

    const [assigneeEditable, setAssigneeEditable] = useState(false);

    const handleChangeAssignee = (e, val) => {
        handleChange('assignee', val?.accountId);
    };

    const handleAssineeEditToggle = () => {
        setAssigneeEditable(prev => !prev);
    };

    const usersWithUnassignee = useMemo(() => {
        const unassignedUser = { accountId: undefined, displayName: t('unassigned') };

        return [unassignedUser, ...jiraUsers];
    }, [jiraUsers, t]);

    return todoAssigneeUser && !assigneeEditable ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JiraUserInfo user={todoAssigneeUser} hideActiveInfo />
            <Tooltip title={t('edit')}>
                <IconButton onClick={handleAssineeEditToggle}>
                    <IconEdit />
                </IconButton>
            </Tooltip>
        </Box>
    ) : (
        <JiraUsersAutocomplete
            defaultUser={todoAssigneeUser}
            users={usersWithUnassignee}
            handleSelection={handleChangeAssignee}
        />
    );
}
