import chroma from 'chroma-js';

const defaultColors = [
    'rgb(180, 0, 0)',
    'rgb(255, 115, 0)',
    'rgb(255, 200, 0)',
    'rgb(133, 204, 0)',
    'rgb(0, 106, 0)'
];

const darkerColors = [
    'rgb(155, 0, 0)',
    'rgb(230, 103, 0)',
    'rgb(204, 160, 0)',
    'rgb(99, 153, 0)',
    'rgb(0, 85, 0)'
];

function ProgressBarColors(percentage, dark = false) {
    const outputColorVal = chroma
        .scale(dark ? darkerColors : defaultColors)
        .domain([0, 25, 50, 75, 100]);
    return outputColorVal(percentage).hex();
}

export default ProgressBarColors;
