import borders from '@assets/theme/base/borders';
import colors from '@assets/theme/base/colors';
import typography from '@assets/theme/base/typography';

// Material Home 2 React helper functions
import pxToRem from '@assets/theme/functions/pxToRem';

const { black, secondary } = colors;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
    styleOverrides: {
        root: {
            'minWidth': pxToRem(160),
            'minHeight': 'unset',
            'padding': `${pxToRem(4.8)} ${pxToRem(16)}`,
            'borderRadius': borderRadius.xl,
            'fontSize': size.sm,
            'color': black.main,
            'transition': 'background-color 300ms ease, color 300ms ease',

            '&:hover, &:focus, &.Mui-selected:hover, &.Mui-selected:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.17)',
                color: black.main,
                fontWeight: 600,
                outline: `3px solid ${secondary.dark}`
            }
        }
    }
};

export default menuItem;
