import { FormControl, Select, MenuItem, Avatar, Box } from '@mui/material';

export function Priority({ value, defaultValue, allowedValues, handleChange }) {
    const handlePriorityChange = e => {
        console.log('handlePriorityChange', e.target.value);
        handleChange('priority', e.target.value);
    };

    const options = allowedValues ?? [];

    const extraProps = defaultValue ? { defaultValue: defaultValue.id } : {};

    return (
        <FormControl variant='standard' fullWidth>
            <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                value={value}
                onChange={handlePriorityChange}
                {...extraProps}
            >
                {options?.map(option => (
                    <MenuItem
                        value={option.id}
                        key={option.id}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Avatar
                                alt={option.name}
                                src={option.iconUrl}
                                sx={{ width: 24, height: 24, mr: 1 }}
                            />
                            {option.name}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
