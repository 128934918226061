import { reportDemoModeAtom } from '@/global-store';
import { Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

function NoSaveInDemoBanner({ sx = {} }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [reportDemoMode] = useAtom(reportDemoModeAtom);

    if (!reportDemoMode) {
        return null;
    }

    return (
        <Alert
            severity='warning'
            sx={{
                'border': '2px solid #333',
                'mx': 2,
                'fontWeight': 600,
                'color': theme.palette.black.main,
                '& .MuiAlert-icon': {
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                ...sx
            }}
        >
            {t('noSaveInDemoMode')}
        </Alert>
    );
}

export default NoSaveInDemoBanner;
