import { Button, ButtonGroup } from '@mui/material';

export default function ButtonSwitch({
    optionAVal,
    optionBVal,
    optionATitle = null,
    optionBTitle = null,
    optionAProps = {},
    optionBProps = {},
    value,
    handleChange
}) {
    return (
        <ButtonGroup aria-label='Disabled elevation buttons' disableElevation fullWidth>
            <Button
                onClick={() => handleChange(optionAVal)}
                variant={value === optionAVal ? 'contained' : 'outlined'}
                {...optionAProps}
            >
                {optionATitle ?? optionAVal}
            </Button>

            <Button
                onClick={() => handleChange(optionBVal)}
                variant={value === optionBVal ? 'contained' : 'outlined'}
                {...optionBProps}
            >
                {optionBTitle ?? optionBVal}
            </Button>
        </ButtonGroup>
    );
}
