import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Ce champ';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} est invalide`,
        required: ({ path }) => `${getFieldName(path)} est un champ obligatoire`,
        defined: ({ path }) => `${getFieldName(path)} doit être défini`,
        notNull: ({ path }) => `${getFieldName(path)} ne peut pas être nul`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} doit être l'une des valeurs suivantes : ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} ne doit pas être l'une des valeurs suivantes : ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} doit être de type \`${i18next.t(type)}\`, ` +
                `mais la valeur finale était : \`${printValue(value, true)}\`` +
                (isCast ? ` (converti de la valeur \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n Si "nul" est destiné à être une valeur vide, assurez-vous de marquer le schéma comme` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) =>
            `${getFieldName(path)} doit comporter exactement ${length} caractères`,
        min: ({ path, min }) => `${getFieldName(path)} doit comporter au moins ${min} caractères`,
        max: ({ path, max }) => `${getFieldName(path)} ne doit pas dépasser ${max} caractères`,
        matches: ({ path, regex }) => `${getFieldName(path)} doit correspondre à : "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} doit être un e-mail valide`,
        url: ({ path }) => `${getFieldName(path)} doit être une URL valide`,
        uuid: ({ path }) => `${getFieldName(path)} doit être un UUID valide`,
        trim: ({ path }) =>
            `${getFieldName(path)} doit être une chaîne sans espaces au début ou à la fin`,
        lowercase: ({ path }) => `${getFieldName(path)} doit être en minuscules`,
        uppercase: ({ path }) => `${getFieldName(path)} doit être en majuscules`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} doit être supérieur ou égal à ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} doit être inférieur ou égal à ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} doit être inférieur à ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} doit être supérieur à ${more}`,
        positive: ({ path }) => `${getFieldName(path)} doit être un nombre positif`,
        negative: ({ path }) => `${getFieldName(path)} doit être un nombre négatif`,
        integer: ({ path }) => `${getFieldName(path)} doit être un entier`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} doit être postérieur à ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} doit être antérieur à ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} contient des clés non spécifiées : ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} doit contenir au moins ${min} éléments`,
        max: ({ path, max }) => `${getFieldName(path)} doit contenir au maximum ${max} éléments`,
        length: ({ path, length }) => `${getFieldName(path)} doit contenir ${length} éléments`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} doit être ${i18next.t(value)}`
    }
};
