import PositionForm from './CustomizeForms/PositionForm';
import AppearanceForm from './CustomizeForms/AppearanceForm';
import AdvancedForm from './CustomizeForms/AdvancedForm';
import FunctionPositionForm from './CustomizeForms/FunctionPositionForm';
import ReadAloudForm from './CustomizeForms/ReadAloudForm';

export const tabsConfig = [
    {
        key: 'position',
        name: 'Position',
        description: 'customizePositionDescription',
        FormComponent: PositionForm
    },
    {
        key: 'appearance',
        name: 'customizeAppearanceName',
        description: 'customizeAppearanceDescription',
        FormComponent: AppearanceForm
    },
    {
        key: 'readAloud',
        name: 'astCustomTReadAloudTitle',
        description: 'astCustomTReadAloudDesc',
        FormComponent: ReadAloudForm
    },
    {
        key: 'funcPosition',
        name: 'customizeFuncPosName',
        description: 'customizeFuncPosDescription',
        FormComponent: FunctionPositionForm
    },
    {
        key: 'advanced',
        name: 'customizeExpertName',
        description: 'customizeExpertDesc',
        FormComponent: AdvancedForm
    }
];

export const inputValues = {
    position: {
        topPosition: { value: '15%', error: false },
        rightPosition: { value: '10px', error: false },
        mobileBottomPosition: { value: '15%', error: false },
        mobileRightPosition: { value: '10px', error: false },
        sidePosition: { value: 'right', error: false },
        mobileSidePosition: { value: 'right', error: false }
    },
    appearance: {
        iconSize: { value: '70px', error: false },
        mobileIconSize: { value: '50px', error: false },
        customIconColor: { value: '#000000', error: false },
        customBackgroundColor: { value: '#000000', error: false },
        externalLogoUrl: { value: '', error: false },
        externalToolbarLogoUrl: { value: '', error: false }
    },
    advanced: {
        maxMagnification: { value: 15, error: false },
        maxMobileMagnification: { value: 3, error: false },
        blacklistContrast: { value: '', error: false },
        blacklistDarkmode: { value: '', error: false },
        blacklistFontsize: { value: '', error: false },
        forceWhiteBackground: { value: '', error: false },
        userLang: { value: 'auto', error: false },
        disableAllShortcuts: { value: false, error: false },
        hideIcon: { value: false, error: false },
        shareLocalStorage: { value: false, error: false },
        forceInlineColor: { value: false, error: false }
    },
    funcPosition: {
        Bluefilter: { value: 'Main', error: false },
        Darkmode: { value: 'Main', error: false },
        TabNavi: { value: 'More', error: false },
        ColorCorrection: { value: 'Main', error: false },
        HideImages: { value: 'Main', error: false },
        LargeCursor: { value: 'More', error: false },
        StopAnimation: { value: 'More', error: false }
    },
    readAloud: {
        sRStartElement: { value: '', error: false },
        sRTextBlock: { value: '', error: false },
        blacklistScreenReader: { value: '', error: false },
        srDictionary: { value: {}, error: false },
        srPhoentic: { value: {}, error: false }
    }
};
