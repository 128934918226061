export default function userHasAccessToEntry(user, entry) {
    if (user.isAdmin) return true;

    if (entry.userId === user.id) return true;

    if (user.primaryOrganisation) {
        if (entry.primaryOrganisation === user.primaryOrganisation) return true;
        if (entry.secondaryOrganisation === user.primaryOrganisation) return true;
    }

    if (user.secondaryOrganisations) {
        if (entry.primaryOrganisation === user.secondaryOrganisations) return true;
        if (entry.secondaryOrganisation === user.secondaryOrganisations) return true;
    }

    return false;
}
