// styles defined in assets/scss/style.scss

function LoadingSpinner({ white = false, scale = 1, minHeight = '240px' }) {
    return (
        <div
            className='loader-container'
            style={{
                transform: `scale(${scale})`,
                minHeight: minHeight
            }}
        >
            <div className={`loader ${white ? 'loader-white' : ''}`} />
        </div>
    );
}

export default LoadingSpinner;
