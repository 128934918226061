import colors from '@assets/theme/base/colors';
import linearGradient from '@assets/theme/functions/linearGradient';

const { primary } = colors;

const stepper = {
    defaultProps: {
        sx: {
            background: linearGradient(primary.light, primary.main),
            p: 2.5,
            borderRadius: 3,
            boxShadow: 4
        }
    }
    /*
    styleOverrides: {
        root: {
            'background': linearGradient(primary.main, primary.light),
            'padding': `${pxToRem(24)} 0 ${pxToRem(16)}`,
            'borderRadius': borderRadius.xl,
            'boxShadow': colored.info,

            '&.MuiPaper-root': {
                backgroundColor: transparent.main
            }
        }
    }
    */
};

export default stepper;
