import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NavBadge({ text = 'demo', sx = {} }) {
    const { t } = useTranslation();

    if (text !== 'demo' && text !== 'beta') {
        text = t(text);
    }

    return (
        <Box
            alignItems='center'
            backgroundColor='#FFD050'
            border='1.5px solid #000'
            borderRadius={9}
            color='#000'
            display='inline-flex'
            fontSize='0.8em'
            fontWeight={500}
            justifyContent='center'
            lineHeight={1.5}
            px={1.4}
            py={0.25}
            sx={sx}
        >
            {text.toUpperCase()}
        </Box>
    );
}
