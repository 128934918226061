import useWindowSize from '@hooks/useWindowSize.jsx';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

function useIsXxlScreen() {
    const { width } = useWindowSize();

    const theme = useTheme();

    const isXxl = useMemo(() => {
        return width >= theme.breakpoints.values.xxl;
    }, [width, theme]);

    return isXxl;
}

export default useIsXxlScreen;
