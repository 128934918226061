const tabs = {
    styleOverrides: {
        root: {
            'position': 'relative',
            'borderRadius': 0,
            'minHeight': 'unset',
            'padding': 0,

            '& .MuiTabs-indicator': {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                height: 3
            }
        },

        flexContainer: {
            height: '100%',
            position: 'relative',
            zIndex: 10
        },

        fixed: {
            overflow: 'unset !important',
            overflowX: 'unset !important'
        },

        vertical: {
            '& .MuiTabs-indicator': {
                width: '100%'
            }
        },

        indicator: {}
    },

    defaultProps: {
        TabIndicatorProps: {
            children: (
                <span
                    className='MuiTabs-indicatorSpan'
                    style={{ maxWidth: '60px', width: '100%', backgroundColor: '#222' }}
                />
            )
        }
    }
};

export default tabs;
