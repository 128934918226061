import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 400,
        padding: '10px',
        textAlign: 'left',
        fontSize: '1rem',
        lineHeight: '1.85rem',
        fontWeight: 0
        // border: '1px solid #dadde9'
    }
}));

export default HtmlTooltip;
