import borders from '@assets/theme/base/borders';
import boxShadows from '@assets/theme/base/boxShadows';
import colors from '@assets/theme/base/colors';
import typography from '@assets/theme/base/typography';

// Material Home 2 React helper functions
import pxToRem from '@assets/theme/functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { black, light } = colors;
const { borderRadius } = borders;

const menu = {
    defaultProps: {
        disableAutoFocusItem: true
    },

    styleOverrides: {
        paper: {
            'minWidth': pxToRem(140),
            'boxShadow': lg,
            'padding': `${pxToRem(8)}`,
            'fontSize': size.sm,
            'color': black.main,
            'textAlign': 'left',
            'backgroundColor': `${light.main} !important`,
            'borderRadius': borderRadius.md,
            'border': '1.5px solid #333',

            '& .MuiList-root': {
                'padding': 0,

                'li:last-child': {
                    marginBottom: '0 !important'
                }
            }
        }
    }
};

export default menu;
