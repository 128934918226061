import colors from '../base/colors';

// dark scrollbar for white background
export const scrollBarStylesOnWhite = {
    '&::-webkit-scrollbar': {
        width: '10px',
        height: '10px'
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent !important',
        borderRadius: '10px',
        border: 'none !important'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#777',
        borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors.secondary.darker
    }
};

// light scrollbar for dark background
export const scrollBarStylesOnBlack = {
    ...scrollBarStylesOnWhite,
    '&::-webkit-scrollbar-thumb': {
        background: '#aaa',
        borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors.secondary.dark
    }
};
