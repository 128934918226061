import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';

export function transformUrlToPath(url) {
    if (!url) return url;

    //remove the protocol
    let result = url.replaceAll('https://', '');
    result = result.replaceAll('http://', '');

    //remove all special characters
    result = result.replaceAll(/\W/g, '-');

    //remove trailing slash
    /*  if (result?.endsWith('-')) {
     result = result.slice(0, -1);
   } */

    return result;
}

export function hexToRgb(hex) {
    // Convert hex color to RGB values
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

export function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrast(hex1, hex2) {
    // Convert hex colors to RGB values
    const rgb1 = hexToRgb(hex1);
    const rgb2 = hexToRgb(hex2);

    var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return ((brightest + 0.05) / (darkest + 0.05)).toFixed(1);
}

export function colorForContrast(value1) {
    if (value1) {
        return value1 > 7 ? '#006400' : value1 > 4.5 ? '#4ac828' : 'red';
    }
}

export function capitalizeFirstLetter(string) {
    if (string && string.trim() !== '') return string.charAt(0).toUpperCase() + string.slice(1);
    return string;
}

export function parseInputString(str) {
    const unitPx = str.slice(-2);
    const unitProc = str.slice(-1);
    if (unitPx === 'px') {
        const value = str.substr(0, str.length - 2);
        return [unitPx, value];
    } else if (unitProc === '%') {
        const value = str.substr(0, str.length - 1);
        return [unitProc, value];
    } else {
        return [false, false];
    }
}

export function displayBiggestTimeUnit(ms) {
    if (ms < 10000) return ms.toFixed(0) + ' ms';

    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        return `${minutes} min`;
    } else {
        return `${seconds} sec`;
    }
}

export function displayBiggestFileUnit(bytes) {
    if (typeof bytes !== 'number') {
        return 'Invalid input';
    }

    const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    for (let i = 0; i < units.length - 1; i++) {
        if (bytes < 1024) {
            // Convert to the next biggest unit if it's not in bytes
            if (i === 0) {
                return `${(bytes / 1024).toFixed(2)} ${units[i + 1]}`;
            } else {
                return `${bytes.toFixed(2)} ${units[i]}`;
            }
        }
        bytes /= 1024;
    }

    // Return YiB if input is extremely large
    return `${bytes.toFixed(2)} ${units[units.length - 1]}`;
}

export function isWholeNumber(str) {
    return /^-?\d+$/.test(str);
}

export function getHashCode(str) {
    var hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function readabilityScoreRating(score) {
    if (score < 30) return 0;
    else if (score < 50) return 1;
    else if (score < 60) return 2;
    else if (score < 70) return 3;
    else if (score < 80) return 4;
    else if (score < 90) return 5;
    else return 6;
}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export function getTimeDifferenceFromNow(date, lang) {
    let options = {};
    if (lang === 'de') options = { locale: de };
    return formatDistanceToNow(date, { addSuffix: true, ...options });
}

export function getFormatedDate(dateVal, lang = 'de') {
    const dateObj = new Date(dateVal);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    };

    const locale = {
        de: 'de-DE',
        en: 'en-GB',
        fr: 'fr-FR',
        it: 'it',
        pt: 'pt',
        pl: 'pl',
        nl: 'nl'
    };

    return dateObj.toLocaleDateString(locale[lang] || locale['de'], options);
}
