import { ProductCard } from '@components/ProductCard';
import { IconAccessible, IconRadar, IconReport } from '@tabler/icons-react';
import Grid from '@mui/material/Unstable_Grid2';

function ProductCards() {
    const products = [
        {
            name: 'Assist',
            link: '/assist',
            icon: <IconAccessible size='2rem' />,
            learnMoreLink: 'https://eye-able.com/assistenzsoftware/'
        },
        {
            name: 'Audit',
            link: '/audit',
            icon: <IconRadar size='2rem' />,
            learnMoreLink: 'https://eye-able.com/wcag-checker/'
        },
        {
            name: 'Report',
            link: '/report/overview',
            icon: <IconReport size='2rem' />,
            learnMoreLink: 'https://eye-able.com/wcag-report/'
        }
    ];

    return (
        <Grid container spacing={4}>
            {products.map(product => (
                <Grid key={product.name} sm={6} xl={4} xs={12}>
                    <ProductCard
                        icon={product.icon}
                        learnMoreLink={product.learnMoreLink}
                        link={product.link}
                        product={product.name}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default ProductCards;
