import OfferContactForm from '@components/OfferContactForm';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

export default function DemoModeBanner({ product }) {
    const { t } = useTranslation();

    return (
        <Grid xs={12}>
            <Box
                bgcolor='secondary.main'
                border='4px solid #000'
                borderRadius={1.5}
                mb={2}
                p={2.5}
                sx={{ borderLeftWidth: '9px', borderRightWidth: '9px' }}
            >
                <Typography component='div' mb={2} variant='h2'>
                    {t('demoModeBoxTitle')} {product}!
                </Typography>

                <Typography color='black.main' fontWeight={500} mb={2} variant='body1'>
                    {t('demoModeBoxText+' + product)}

                    <br />

                    {t('demoModeBoxCallToAction', { product })}
                </Typography>

                <OfferContactForm product={'Eye-Able® ' + product} showBookConsultation />
            </Box>
        </Grid>
    );
}
