import borders from '@assets/theme/base/borders';
import boxShadows from '@assets/theme/base/boxShadows';
import colors from '@assets/theme/base/colors';
import boxShadow from '@assets/theme/functions/boxShadow';
import pxToRem from '@assets/theme/functions/pxToRem';

const { white, black, info, secondary, primary } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

const slider = {
    styleOverrides: {
        root: {
            'width': '100%',

            '& .MuiSlider-active, & .Mui-focusVisible': {
                boxShadow: 'none !important'
            },

            '& .MuiSlider-valueLabel': {
                color: white.main,
                backgroundColor: primary.main
            }
        },

        rail: {
            height: pxToRem(4),
            background: '#999',
            borderRadius: borderRadius.sm,
            opacity: 1
        },

        track: {
            background: secondary.dark,
            height: pxToRem(5),
            position: 'relative',
            border: '1.5px solid #000',
            borderRadius: borderRadius.lg,
            zIndex: 1
        },

        thumb: {
            'width': pxToRem(20),
            'height': pxToRem(20),
            'backgroundColor': secondary.main,
            'zIndex': 10,
            'boxShadow': sliderBoxShadow.thumb,
            'border': `${borderWidth[6]} solid ${primary.main}`,
            'transition': 'all 200ms linear',

            '&:hover': {
                boxShadow: 'none'
            },

            '&:active': {
                transform: 'translate(-50%, -50%) scale(1.4)'
            },

            '&.Mui-active': { boxShadow: boxShadow([0, 0], [0, 14], info.main, 0.16) }
        }
    }
};

export default slider;
