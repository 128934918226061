import { Box } from '@mui/material';

export default function InputHelperText({ text, isError = false, sx = {} }) {
    const textColor = isError ? '#a82424' : '#555';
    return (
        <Box sx={{ fontSize: '.8rem', color: textColor, marginTop: 1, textAlign: 'left', ...sx }}>
            {text}
        </Box>
    );
}
