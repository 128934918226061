import colors from '@assets/theme/base/colors';
const { white, black, secondary } = colors;

const stepLabel = {
    defaultProps: {
        sx: {
            '& .MuiSvgIcon-root': {
                'width': '34px',
                'height': '34px',
                'color': white.main,
                'borderRadius': '50%',

                '&.Mui-active': {
                    color: secondary.main,
                    outline: '2.5px solid #fff',
                    outlineOffset: '-1px',
                    transform: 'scale(1.125)'
                },

                '&.Mui-completed': {
                    color: white.main,
                    opacity: 0.85
                }
            },
            '& .MuiStepLabel-iconContainer': {
                p: 0
            },
            '& .MuiStepIcon-text': {
                fill: black.main,
                fontWeight: 600
            }
        }
    }
    /*
    styleOverrides: {
        label: {
            'marginTop': `${pxToRem(8)} !important`,
            'fontWeight': fontWeightRegular,
            'fontSize': size.xs,
            'color': '#9fc9ff',
            'textTransform': 'uppercase',

            '&.Mui-active': {
                fontWeight: `${fontWeightRegular} !important`,
                color: `${rgba(white.main, 0.8)} !important`
            },

            '&.Mui-completed': {
                fontWeight: `${fontWeightRegular} !important`,
                color: `${rgba(white.main, 0.8)} !important`
            }
        }
    }
    */
};

export default stepLabel;
