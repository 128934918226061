import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Questo campo';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} non è valido`,
        required: ({ path }) => `${getFieldName(path)} è un campo obbligatorio`,
        defined: ({ path }) => `${getFieldName(path)} deve essere definito`,
        notNull: ({ path }) => `${getFieldName(path)} non può essere nullo`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} deve essere uno dei seguenti valori: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} non deve essere uno dei seguenti valori: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} deve essere di tipo \`${i18next.t(type)}\`, ` +
                `ma il valore finale era: \`${printValue(value, true)}\`` +
                (isCast ? ` (convertito dal valore \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n Se "null" è inteso come un valore vuoto, assicurarsi di contrassegnare lo schema come` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) =>
            `${getFieldName(path)} deve avere esattamente ${length} caratteri`,
        min: ({ path, min }) => `${getFieldName(path)} deve avere almeno ${min} caratteri`,
        max: ({ path, max }) => `${getFieldName(path)} non deve superare ${max} caratteri`,
        matches: ({ path, regex }) => `${getFieldName(path)} deve corrispondere a: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} deve essere un'email valida`,
        url: ({ path }) => `${getFieldName(path)} deve essere un URL valido`,
        uuid: ({ path }) => `${getFieldName(path)} deve essere un UUID valido`,
        trim: ({ path }) =>
            `${getFieldName(path)} deve essere una stringa senza spazi all'inizio o alla fine`,
        lowercase: ({ path }) => `${getFieldName(path)} deve essere in minuscolo`,
        uppercase: ({ path }) => `${getFieldName(path)} deve essere in maiuscolo`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} deve essere maggiore o uguale a ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} deve essere minore o uguale a ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} deve essere minore di ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} deve essere maggiore di ${more}`,
        positive: ({ path }) => `${getFieldName(path)} deve essere un numero positivo`,
        negative: ({ path }) => `${getFieldName(path)} deve essere un numero negativo`,
        integer: ({ path }) => `${getFieldName(path)} deve essere un intero`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} deve essere successivo a ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} deve essere precedente a ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} contiene chiavi non specificate: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} deve contenere almeno ${min} elementi`,
        max: ({ path, max }) => `${getFieldName(path)} non deve contenere più di ${max} elementi`,
        length: ({ path, length }) => `${getFieldName(path)} deve contenere ${length} elementi`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} deve essere ${i18next.t(value)}`
    }
};
