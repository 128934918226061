import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'To pole';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} jest nieprawidłowy`,
        required: ({ path }) => `${getFieldName(path)} jest wymaganym polem`,
        defined: ({ path }) => `${getFieldName(path)} musi być zdefiniowany`,
        notNull: ({ path }) => `${getFieldName(path)} nie może być null`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} musi być jedną z następujących wartości: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} nie może być jedną z następujących wartości: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} musi być typu \`${i18next.t(type)}\`, ` +
                `ale ostateczna wartość była: \`${printValue(value, true)}\`` +
                (isCast
                    ? ` (przekonwertowano z wartości \`${printValue(originalValue, true)}\`).`
                    : '.');

            if (value === null) {
                msg +=
                    `\n Jeśli "null" jest przeznaczone na pustą wartość, upewnij się, że schemat jest oznaczony jako` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) => `${getFieldName(path)} musi mieć dokładnie ${length} znaków`,
        min: ({ path, min }) => `${getFieldName(path)} musi mieć przynajmniej ${min} znaków`,
        max: ({ path, max }) => `${getFieldName(path)} może mieć najwyżej ${max} znaków`,
        matches: ({ path, regex }) => `${getFieldName(path)} musi pasować do wzoru: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} musi być prawidłowym adresem email`,
        url: ({ path }) => `${getFieldName(path)} musi być prawidłowym adresem URL`,
        uuid: ({ path }) => `${getFieldName(path)} musi być prawidłowym UUID`,
        trim: ({ path }) => `${getFieldName(path)} musi być bez początkowych i końcowych spacji`,
        lowercase: ({ path }) => `${getFieldName(path)} musi być napisany małymi literami`,
        uppercase: ({ path }) => `${getFieldName(path)} musi być napisany wielkimi literami`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} musi być większe lub równe ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} musi być mniejsze lub równe ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} musi być mniejsze niż ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} musi być większe niż ${more}`,
        positive: ({ path }) => `${getFieldName(path)} musi być liczbą dodatnią`,
        negative: ({ path }) => `${getFieldName(path)} musi być liczbą ujemną`,
        integer: ({ path }) => `${getFieldName(path)} musi być liczbą całkowitą`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} pole musi być później niż ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} pole musi być wcześniej niż ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} pole zawiera nieokreślone klucze: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) =>
            `${getFieldName(path)} pole musi mieć przynajmniej ${min} elementów`,
        max: ({ path, max }) => `${getFieldName(path)} pole może mieć najwyżej ${max} elementów`,
        length: ({ path, length }) => `${getFieldName(path)} musi mieć ${length} elementów`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} pole musi być ${i18next.t(value)}`
    }
};
