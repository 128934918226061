import { usePostJiraIssue, usePostMulJiraIssues } from '@http/queries';
import { useTranslation } from 'react-i18next';
import { todoCategoryProps } from '@pages/report/MyGoals/components/utils';
import { getFormatedDate } from '@helpers/functions';
import format from 'date-fns/format';
import { useAtom } from 'jotai';
import { todosDataAtom } from '@/global-store';

function useJiraExport() {
    const { t } = useTranslation();
    const postJiraIssueMutation = usePostJiraIssue();
    const postMulJiraIssueMutation = usePostMulJiraIssues();
    const [todosData] = useAtom(todosDataAtom);

    const prepareIssueBody = ({
        todo,
        projectKey,
        reporterId,
        issuetype,
        duedate,
        priority,
        components,
        assigneeId
    }) => {
        const { label, i18nLabelKey } = todoCategoryProps[todo.category];
        let issueDescription = '';
        issueDescription += `Domain: ${todo.domain}\n`;
        issueDescription += `${t('category')}: ${i18nLabelKey ? t(i18nLabelKey) : label}\n`;
        if (todo?.dateCreated)
            issueDescription += `${t('dateCreated')}: ${getFormatedDate(todo.dateCreated)}\n`;
        if (todo?.goToPage)
            issueDescription += `${t('jumpToError')}: [${t('openInEyeableDashboard')}|https://dashboard.eye-able.com${todo?.goToPage}/?d=${todo.domain}|smart-link]\n`;

        const issueBody = {
            fields: {
                description: issueDescription,
                project: {
                    key: projectKey
                },
                summary: 'Eye-Able®: ' + todo.title,
                issuetype: { id: issuetype },
                duedate: format(duedate, 'yyyy-MM-dd'),
                priority: { id: priority }
            }
        };
        if (reporterId)
            issueBody.fields.reporter = {
                id: reporterId
            };
        if (components && components.length)
            issueBody.fields.components = components.map(component => ({
                id: component.id
            }));

        if (assigneeId)
            issueBody.fields.assignee = {
                id: assigneeId
            };
        return issueBody;
    };

    const handlePostToJira = async props => {
        let assigneeId = props.assignee;
        const issueBody = prepareIssueBody({ ...props, assigneeId });
        console.log('handlePostToJira', issueBody);

        try {
            const jiraResponse = await postJiraIssueMutation.mutateAsync({ issue: issueBody });

            if (jiraResponse && jiraResponse.id) {
                return jiraResponse;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const handlePostMulToJira = async props => {
        const { todos, ...config } = props;
        const limit = 15;

        const issues = todos.map(todo => {
            let assigneeId = config.assignee;
            if (todo?.assignee && todosData?.jira?.jiraUsersMap?.[todo.assignee])
                assigneeId = todosData.jira.jiraUsersMap[todo.assignee];

            const body = prepareIssueBody({ todo, ...config, assigneeId });
            return { todoId: todo.id, ...body };
        });
        const responseMap = {};
        if (issues.length) {
            for (let start = 0; start < issues.length; start += limit) {
                const batch = issues.slice(start, start + limit);
                console.log('batch', start, batch);
                try {
                    const jiraResponse = await postMulJiraIssueMutation.mutateAsync({
                        issues: batch
                    });

                    if (jiraResponse) {
                        let errorsIndex = [];
                        const issuesCreated = [...jiraResponse.issues];
                        if (jiraResponse.errors?.length > 0)
                            errorsIndex = jiraResponse.errors.map(
                                error => error.failedElementNumber
                            );
                        for (let i = start; i < start + limit; i++) {
                            if (issuesCreated.length > 0 && !errorsIndex.includes(i)) {
                                const todoId = todos[i].id;
                                const { id, key } = issuesCreated.shift();
                                responseMap[todoId] = { jira: { id, key }, todoId };
                            }
                        }
                    }
                } catch (error) {
                    console.log('handlePostMulToJira_error', batch, error);
                }
            }
        }
        return Object.values(responseMap);
    };
    return { handlePostToJira, handlePostMulToJira };
}

export default useJiraExport;
